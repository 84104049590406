/* @import "/node_modules/antd/dist/antd.css"; */

html {
  box-sizing: border-box; /* Include padding and border as part of element width and height */
  font-size: 62.5% !important; /* Font sizes in REM are now base ten => 1.2 rem = 12px */
  height: 100%;
  scroll-behavior: smooth;
}

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**************/
/* Font Faces */
/**************/

/* HK Grotesk */

@font-face {
  font-family: 'HK Grotesk';
  src: url('./assets/fonts/hk-grotesk/HKGrotesk-Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('./assets/fonts/hk-grotesk/HKGrotesk-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('./assets/fonts/hk-grotesk/HKGrotesk-SemiBold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('./assets/fonts/hk-grotesk/HKGrotesk-SemiBoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('./assets/fonts/hk-grotesk/HKGrotesk-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('./assets/fonts/hk-grotesk/HKGrotesk-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('./assets/fonts/hk-grotesk/HKGrotesk-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('./assets/fonts/hk-grotesk/HKGrotesk-Italic.otf') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('./assets/fonts/hk-grotesk/HKGrotesk-Light.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('./assets/fonts/hk-grotesk/HKGrotesk-LightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

/* Overpass */

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-heavy.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-heavy-italic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-extrabold.otf') format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-extrabold-italic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-bold-italic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-semibold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-semibold-italic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-regular.otf') format('opentype');
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-italic.otf') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-light-italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-extralight.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-extralight-italic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-thin.otf') format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: 'Overpass';
  src: url('./assets/fonts/overpass/overpass-thin-italic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}
