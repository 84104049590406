.SVGSpacer {
  aspect-ratio: 900/300;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.spacerLayer1 {
  background-image: url('./assets/SVGWavesBG2.svg');
}

.spacerLayer2 {
  background-image: url('./assets/SVGWavesBG3.svg');
}

.flip {
  transform: rotate(180deg);
}